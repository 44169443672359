export enum EErpBasketTableField {
  NeedName = 'name',
  Image = 'childImage',
  Name = 'childName',
  Supplier = 'childSupplier',
  DeliveryTo = 'childDeliveryTo',
  Count = 'childCount',
  Price = 'childPrice',
  Cost = 'childCost',
  Action = 'childAction',
  MtrCode = 'childMtrCode' // Для таблицы в корзине OCI
}
