export enum EBasketWarningType {
  NoStock = 'no_stock',
  NoCategory = 'no_category',
  PriceChanged = 'price_changed',
  DeliveryChanged = 'delivery_changed',
  NoOffers = 'no_offers',
  NoCount = 'no_count',
  NoMappings = 'no_mappings',
  LateDate = 'late_date',
  MinSum = 'min_sum',
  CountUpdated = 'count_updated',
  BudgetLimit = 'budget_limit',
  ErpError = 'erp_error',
  OutOfCount = 'out_of_count',
  QuantOverflow = 'quant_overflow',
  QuantStep = 'quant_step',
  QuantMin = 'quant_min',
  AutoSelectedAnalog = 'auto_selected_analog',
  QuantityLack = 'quantity_lack',
  PermissibleExcess = 'permissible_excess',
  NoMapping = 'no_mapping',
  GeneralizedMapping = 'generalized_mapping',
  MpOrderMaxCost = 'MP_ORDER_MAX_COST',
  MpOrderMinCost = 'MP_ORDER_MIN_COST',
  ErpOrderMaxCost = 'ERP_ORDER_MAX_COST',
  ErpOrderMinCost = 'ERP_ORDER_MIN_COST',
  OfferGone = 'offer_gone',
  ItemMaxCost = 'ITEM_MAX_COST',
  ItemMinCost = 'ITEM_MIN_COST',
  OciNameTemplateError = 'OCI_NAME_TEMPLATE_ERROR' // Кастомная ошибка для OCI
}
